.app__gallery
{
    display: flex;
    flex-direction: row;
    background: var(--color-black);
    padding: 4rem 0 4rem 4rem;
}

.gallery-content
{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 400px;
    padding-right: 2rem;
}

.gallery-content button
{
    margin-top: 1rem;
}

.gallery-images
{
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.images-container
{
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    scrollbar-width: none;
}

.images-container::-webkit-scrollbar 
{
    display: none;
}

.images-card
{
    position: relative;
    min-width: 300px;
    height: 400px;
    margin-right: 2rem;
}

.image-icon
{
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0.5;
    transition: 0.8s ease-in-out;
    cursor: pointer;
}

.images-card img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: 0.8s ease-in-out;
}

.images-card:hover img
{
    opacity: 0.35;
}

.images-card:hover .image-icon
{
    opacity: 1;
}

.gallery-images-arrows
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.arrow-icon
{
    color: var(--color-golden);
    background-color: var(--color-black);
    font-size: 2rem;
    cursor: pointer;
    border-radius: 5px;
}

.arrow-icon:hover
{
    color: var(--color-white);
}


@media screen and (min-width: 1440px) {

    .gallery-content button
    {
        margin-top: 2rem;
    }

    .gallery-content {
        min-width: 600px;
        padding-right: 4rem;
    }

    .images-card {
        min-width: 320px;
        height: 547px;
    }
    
}

@media screen and (max-width: 1024px) {

    .app__gallery {
        flex-direction: column;
        padding-right: 4rem;
    }

    .gallery-images {
        max-width: 100%;
        margin-top: 3rem;
    }

    .images-container
    {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        scrollbar-width: none;
        margin-right: 0;
    }

    .gallery-content
    {
        padding-right: 0;
    }

}

@media screen and (max-width: 768px) {
    .app__gallery {
        padding: 4rem 4rem 4rem 4rem;
    }
}

@media screen and (max-width: 425px) {
    .app__gallery {
        padding: 4rem 4rem 4rem 4rem;
    }  
    
    .gallery-content {
        min-width: 100%;
    }

    .images-card {
        min-width: 200px;
        height: 320px;
    }
}