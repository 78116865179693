.app__footer
{
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: var(--color-black);
    padding-top: 0;
}

.footer-links
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4rem;
    padding: 0 1rem;
}

.links-contact, .links-logo, .links-work
{
    flex: 1;
    margin: 1rem;
    text-align: center;
}

.links-logo img:first-child
{
    width: 210px;
    margin-bottom: 0.65rem;
}

.links-work p:nth-child(2n + 1)
{
    margin-bottom: 1rem;
}

.footer-links-icons
{
    margin-top: 0.85rem;
}

.footer-links-icons svg
{
    color: var(--color-white);
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.footer-links-icons svg:hover
{
    color: var(--color-golden);
}

.footer-headtext
{
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 1rem;
}

.footer__copyright
{
    margin-top: 3rem;
}

@media screen and (min-width: 1440px) {
    .footer-headtext {
        font-size: 45px;
        line-height: 51.6px;
    }
}

@media screen and (max-width: 1024px) {
    .footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    .links-contact,
    .links-logo,
    .links-work {
        margin: 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .app__footer {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 350px) {
    .links-logo img:first-child {
        width: 80%;
    }
}