.app__menuItem
{
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
}

.menuItem-head
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuItem-sub
{
    width: 100%;
    margin-top: 1rem;
}

.menuItem-dash 
{
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.menuItem-price 
{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.menuItem-name
{
    flex: 1;
}



