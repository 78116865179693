.chef-content
{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
}

.content-quote
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.content-quote img
{
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.chef-sign
{
    width: 100%;
    margin-top: 1rem;
}

.chef-sign p:first-child
{
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.chef-sign img
{
    width: 230px;
    margin-top: 3rem;
}

@media screen and (min-width: 1440px) {
    .chef-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .chef-sign img {
        width: 70%;
    }
}