.app__specialMenu
{
    display: flex;
    flex-direction: column;
    background: var(--color-black);
}

.specialMenu-title
{
    margin-bottom: 2rem;
    text-align: center;
}

.specialMenu-menu
{
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.specialMenu-menu-heading
{
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

.specialMenu-menu-wine, .specialMenu-menu-cocktails
{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.specialMenu-menu-img
{
    width: 380px;
    margin: 0 4rem;
}

.specialMenu-menu-img img
{
    width: 100%;
    height: auto;
}

.specialMenu-menu-items
{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    width: 100%;
}


@media screen and (max-width: 1024px) {
    .specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .specialMenu-menu-img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 768px) {
    .specialMenu-menu-img {
        width: 80%;
    }

    .specialMenu-menu-heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}