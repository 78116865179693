.laurel-awards
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3rem;
}

.laurel-award-card
{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;
    margin: 1rem;
}

.card-content
{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

@media screen and (min-width: 1440px) {
    .laurel-award-card
    {
        min-width: 320px;
    }
}

.laurel-award-card img
{
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 450px) {
    .laurel_award-card 
    {
        min-width: 100%;
        margin: 1rem 0;
    }
}