.app__navbar
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    padding: 1rem 2rem;
}

.navbar-logo
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-logo img
{
    width: 120px;
}

@media screen and (min-width: 1440px)
{
    .navbar-logo img
    {
        width: 210px;
    }

}

.navbar-links
{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
}

.navbar-links li
{
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-links li:hover
{
    color: goldenrod;
}

.navbar-login
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-login a
{
    text-decoration: none;
    margin: 0 1rem;
    transition: 0.5s ease;
}

.navbar-login a:hover
{
    border-bottom: 1px solid var(--color-golden);
}

.navbar-login div
{
    width: 1px;
    height: 30px;
    background-color: var(--color-grey);
}

.navbar-menu
{
    display: none;
}

.navbar-menu-smallscreen
{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.navbar-menu-links
{
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.navbar-menu-links li a
{
    font-size: 24px;
    font-family: var(--font-base);
    color: #fff;
    cursor: pointer;
    transition: 0.4s ease;
}

@media screen and (max-width: 425px)
{
    .navbar-menu-links li a
    {
        font-size: 20px;
    }
    .navbar-menu-links
    {
        gap: 60px;
    }
}

.navbar-menu-links li a:hover
{
    color: goldenrod;
    transition: 0.4s ease;
}

.navbar-menu-smallscreen .close
{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 27px;
    cursor: pointer;
    color: var(--color-golden);
}

@media screen and (max-width: 768px)
{
    .app__navbar
    {
        padding: 1rem;
    }

    .navbar-links
    {
        display: none;
    }

    .navbar-menu
    {
        display: flex;
    }
}


@media screen and (max-width: 425px)
{
    .navbar-login
    {
        display: none;
    }

    .navbar-logo img
    {
        width: 80px;
    }
}

